import { graphql } from 'gatsby'
import React from 'react'
import Form from 'src/components/form/form'
import Layout from 'src/components/layout/layout'
import Page from 'src/components/page/page'
import SEO from 'src/components/seo'

const ContactPage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { html, frontmatter } = markdownRemark

  const contactForm = [
    {
      label: 'Votre nom',
      name: 'nom',
      type: 'text',
      size: 6,
      validators: {
        required: `À qui ai-je l'honneur  🙂 ?`,
        maxLength: {
          value: 254,
          message: 'Un nom si long ne doit pas être évident au quotidien... 🙃',
        },
      },
    },
    {
      label: 'Votre email',
      name: 'email',
      type: 'text',
      inputMode: 'email',
      size: 6,
      validators: {
        required: `Afin de vous répondre, il est préférable que je sache où 🤘`,
        pattern: {
          value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: 'Cette adresse email ne semble pas correcte... 🤭',
        },
        maxLength: {
          value: 254,
          message: 'Vous ne vous trompez jamais lorsque vous la dictez 🙃 ?',
        },
      },
    },
    {
      label: 'Votre message',
      name: 'message',
      type: 'textarea',
      size: 12,
      validators: { required: 'Un message vide  🤔 ?' },
    },
  ]

  return (
    <Layout isMini={true}>
      <SEO title={frontmatter.title} description={frontmatter.description} />
      <Page content={html} />
      <Form id="form-contact" fields={contactForm} submitLabel="Envoyer" />
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
      }
    }
  }
`
