import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { ErrorMessage, useForm } from 'react-hook-form'
import Button from 'src/components/button/button'
import FormItemError from './error/error'
import style from './form.module.scss'
import FormInput from './input/input'
import FormItem from './item/item'

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Form = (data) => {
  const { register, handleSubmit, errors, reset } = useForm({
    validateCriteriaMode: 'all',
  })

  const [success, setSuccess] = useState('')
  const [failed, setFailed] = useState('')

  const showMessage = (type, message) => {
    type === 'success' ? setSuccess(message) : setFailed(message)

    setTimeout(() => {
      type === 'success' ? setSuccess('') : setFailed('')
    }, 10000)
  }

  const onSubmit = async (values) => {
    try {
      await fetch(data.netlifyForm ? document.location.href : data.action, {
        method: data.method,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ ...values }),
      })

      showMessage('success', 'Message envoyé avec succès 👍')
      reset()
    } catch (error) {
      showMessage('error', `Erreur lors de l'envoi du formulaire 😕`)
      console.log(error)
    }
  }

  return (
    <>
      {success && (
        <p className={`${style.toast} ${style.success}`}>{success}</p>
      )}
      {failed && <p className={`${style.toast} ${style.error}`}>{failed}</p>}

      <form
        name={data.id}
        method={data.method}
        className={style.form}
        {...(data.netlifyForm
          ? { 'data-netlify': true, 'netlify-honeypot': 'bot-field' }
          : { action: data.action })}
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          type="hidden"
          name="form-name"
          ref={register}
          defaultValue={data.id}
        />

        {data.netlifyForm && <input type="hidden" name="bot-field" />}

        {data.fields.map((field) => (
          <FormItem key={field.name} size={field.size}>
            <FormInput
              field={field}
              id={data.id}
              ref={register({ ...field.validators })}
              error={errors[field.name]}
            />

            <FormItemError>
              <ErrorMessage errors={errors} name={field.name}>
                {({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <p key={type}>{message}</p>
                  ))
                }
              </ErrorMessage>
            </FormItemError>
          </FormItem>
        ))}

        <FormItem>
          <Button type="submit">{data.submitLabel}</Button>
        </FormItem>
      </form>
    </>
  )
}

Form.defaultProps = {
  action: '/',
  method: 'POST',
  className: '',
  netlifyForm: true,
  fields: [],
}

Form.propTypes = {
  id: PropTypes.string.isRequired,
  method: PropTypes.string,
  className: PropTypes.string,
  netlifyForm: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.object),
}

export default Form
