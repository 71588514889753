import React, { useState } from 'react'
import style from './input.module.scss'

const FormInput = React.forwardRef(({ field, id, error }, ref) => {
  const [focus, setFocus] = useState(false)
  return (
    <div
      className={`${focus ? style.focus : ''}${error ? ' ' + style.error : ''}`}
    >
      <label className={style.label} htmlFor={`${id}-${field.name}`}>
        {field.label}
      </label>

      {field.type === 'textarea' ? (
        <textarea
          className={`${style.input} ${style.textarea}`}
          id={`${id}-${field.name}`}
          name={field.name}
          ref={ref}
          defaultValue={field?.defaultValue}
          placeholder={field?.placeholder}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        ></textarea>
      ) : (
        <input
          className={style.input}
          id={`${id}-${field.name}`}
          type={field.type}
          inputMode={field?.inputMode || 'text'}
          name={field.name}
          ref={ref}
          defaultValue={field?.defaultValue}
          placeholder={field?.placeholder}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      )}
    </div>
  )
})

export default FormInput
