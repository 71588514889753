import React from 'react'
import style from './item.module.scss'

const FormItem = ({ children, size }) => (
  <div className={style.item} style={{ '--item-size': size }}>
    {children}
  </div>
)

FormItem.defaultProps = {
  size: 12,
}

export default FormItem
